import { css } from '@emotion/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import theme from '../theme/theme'
import styled from '@emotion/styled'

const styledUtensils = css({
  color: theme.light,
  fontSize: '2.6rem',
  marginRight: '1px',
})

const RedBox = styled.div({
  backgroundColor: theme.primary,
  width: '50px',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '0.8rem',
  borderRadius: '2px',
})

export const Logo = () => (
  <RedBox>
    <FontAwesomeIcon icon={faUtensils} css={styledUtensils} />
  </RedBox>
)
