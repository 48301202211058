import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import theme from '../theme/theme'
import color from 'color'

const Input = styled.input({
  // @ts-ignore
  fontSize: '1.0rem',
  fontFamily: 'Open Sans, sans-sesrif',
  padding: '0.8rem',
  width: '22rem',
  color: theme.dark,
  border: `1px solid ${color(theme.dark).lighten(2)}`,
  borderRadius: '3px',
  '::placeholder': {
    color: `${color(theme.dark).lighten(1.3)}`,
    opacity: '1',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  ':focus': {
    borderColor: `${color(theme.primary).alpha(0.5)}`,
    boxShadow: `0 0 10px ${color(theme.primary).alpha(0.5)}`,
  },
})
const Button = styled.input({
  display: 'none',
})

const hiddenStyle = css({
  display: 'none',
})

export class LoginForm extends Component {
  render() {
    return (
      <form action="/speiseliste/" onSubmit={this.submit} method="POST">
        <input
          css={hiddenStyle}
          name="username"
          defaultValue="user"
          autoComplete="username"
        />
        <Input
          type="password"
          name="password"
          autoComplete="new-password"
          placeholder="Passwort eingeben"
        />
        <Button type="submit" />
      </form>
    )
  }
  private submit = () => {
    console.log('submit')
  }
}
