import React from 'react'
import { LandingTransition, PageTransition } from '../components/transition'
import { LoginForm } from '../components/login-form'
import { Logo } from '../components/logo'
import theme from '../theme/theme'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const styledContainer = css({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',

  backgroundColor: theme.light,
})

const Content = styled.div({
  display: 'flex',
  flex: 1,
  flexGrow: 3,
  margin: '3.2vw',
  marginBottom: '20vh',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
})

const Headline = styled.h1({
  fontSize: '1.8rem',
  fontWeight: '600',
  color: theme.dark,
  lineHeight: '2.1rem',
  letterSpacing: '-0.07rem',
})
const IndexPage = () => (
  <LandingTransition css={styledContainer}>
    <Content>
      <div>
        <Logo />
        <Headline>
          Finde heraus, was es heute <br />
          zu essen gibt.
        </Headline>
        <LoginForm />
      </div>
    </Content>
  </LandingTransition>
)

export default IndexPage
